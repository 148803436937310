import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import {
  CalculationType,
  ExtendedCapsule,
  ExtendedCustomer,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "./CustomTypes";
import { OrdersDocument, OrderState } from "../../model/orders.types";
import ConfiguratorHelper from "./ConfiguratorHelper";
import orderCompareUtils, { MaterialChangeStatistics } from "./orderCompareUtils";
import orderUtils from "../../utils/orderUtils";
import LanguageSelectionDropdown from "../common/LanguageSelectionDropdown";

interface ConfigurationConfirmationModalProps {
  activeType: string;
  preferences: Preferences;
  calculations: Array<CalculationType>;
  recipe: Array<SelectedCommoditiesDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  selectedCapsule: ExtendedCapsule | null;
  customer: ExtendedCustomer | null;
  onPreferenceChange: (path: string, item: any) => void;
  onCreateOffer: (action: "update" | "create" | "updateToOffer") => Promise<void>;
  originOrder?: OrdersDocument;
}

interface ConfigurationConfirmationModalState {
  loading: boolean;
  show: boolean;
  title: string;
  subtitle: string;
  note: string;
  showChanges: boolean;
  action: "update" | "create";
  changes: Array<{ type: string; message: string }> | null;
  statistic: MaterialChangeStatistics | null;
  step: number;
}

class ConfigurationConfirmationModal extends PureComponent<
  ConfigurationConfirmationModalProps,
  ConfigurationConfirmationModalState
> {
  constructor(props: ConfigurationConfirmationModalProps) {
    super(props);
    const { preferences } = props;
    this.state = {
      show: false,
      title: preferences.title,
      subtitle: preferences.subtitle,
      note: preferences.note,
      loading: false,
      showChanges: !!props.originOrder,
      changes: null,
      statistic: null,
      action: !props.originOrder ? "create" : "update",
      step: 1
    };
  }

  handleShow = () => this.setState({ show: true, step: 1 });
  handleClose = () => this.setState({ show: false });

  handleNextStep = () => this.setState({ step: this.state.step + 1 });
  handlePrevStep = () => this.setState({ step: this.state.step - 1 });

  handleTitleChange = (value: string) => this.setState({ title: value });
  handleSubtitleChange = (value: string) => this.setState({ subtitle: value });
  handleNoteChange = (value: string) => this.setState({ note: value });

  handleChangeAction = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({ action: e.currentTarget.value as "update" | "create" });

  /**
   * Handle create or update offer
   * @param hasFatal flag if fatal error was found
   * @param hasError flag if error was found
   */
  handleCreateOffer = async (hasFatal: boolean, hasError: boolean) => {
    this.setState({ loading: true });
    const { originOrder } = this.props;
    let action: "update" | "create" | "updateToOffer" = this.state.action;
    if (!originOrder) action = "create";
    else if (hasFatal) action = "create";
    else if (
      hasError &&
      action === "update" &&
      (orderUtils.isOrder(originOrder) ||
        [OrderState.OFFER_APPROVED, OrderState.OFFER_RELEASED].includes(originOrder.state))
    )
      action = "updateToOffer";
    await this.props.onCreateOffer(action);
    this.setState({ loading: false, show: false });
  };

  /**
   * Analyze changes and statistics
   */
  analyzeChanges = () => {
    const { activeType, preferences, recipe, selectedPackaging, selectedCapsule, calculations, customer, originOrder } =
      this.props;
    if (!originOrder || !this.state.show) return;
    const newOrder = ConfiguratorHelper.createOrderObject(
      activeType,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      calculations,
      customer!,
      originOrder!.identifier
    );
    if (!newOrder) {
      this.setState({ changes: [{ type: "fatal", message: "Order could not be created" }] });
      return;
    }
    const { issues, statistic } = orderCompareUtils.compareOrders(originOrder, newOrder);
    this.setState({ changes: issues, statistic });
  };

  componentDidMount() {
    if (this.props.originOrder) this.analyzeChanges();
  }

  componentDidUpdate(
    prevProps: Readonly<ConfigurationConfirmationModalProps>,
    prevState: Readonly<ConfigurationConfirmationModalState>
  ) {
    const { preferences, recipe, selectedPackaging, selectedCapsule, calculations } = this.props;
    if (!_.isEqual(prevProps.preferences, preferences)) {
      const { title, subtitle, note } = this.state;
      let newState: any = {};
      if (preferences.title !== title) newState.title = preferences.title;
      if (preferences.subtitle !== subtitle) newState.subtitle = preferences.subtitle;
      if (preferences.note !== note) newState.note = preferences.note;
      if (!_.isEmpty(newState)) this.setState({ ...newState });
    }
    if (
      (this.state.show && this.state.show !== prevState.show) ||
      (this.props.originOrder &&
        (!_.isEqual(prevProps.recipe, recipe) ||
          !_.isEqual(prevProps.selectedPackaging, selectedPackaging) ||
          !_.isEqual(prevProps.selectedCapsule, selectedCapsule) ||
          !_.isEqual(prevProps.calculations, calculations)))
    )
      this.analyzeChanges();
  }

  /**
   * Render statistics
   * @param headline text for heading
   * @param statistic triple with added, changed and removed count
   * @param hideNumber optional, flag to hide the number values
   * @returns {JSX.Element} html displaying the statistics
   */
  renderStatistics = (headline: string, statistic: [number, number, number], hideNumber?: boolean): JSX.Element => {
    const [added, changed, removed] = statistic;
    return (
      <>
        <h3 className="kt-section__title mb-2">{headline}</h3>
        <div className="row mb-3">
          <div className="col-4 pt-2 pb-2 text-center" style={{ backgroundColor: added > 0 ? "#ffd7d1" : "#fafafa" }}>
            <span className="kt-font-bold kt-font-dark">{hideNumber ? "" : `${added} `}added</span>
          </div>
          <div className="col-4 pt-2 pb-2 text-center" style={{ backgroundColor: changed > 0 ? "#ffd7d1" : "#fafafa" }}>
            <span className="kt-font-bold kt-font-dark">{hideNumber ? "" : `${changed} `}edited</span>
          </div>
          <div className="col-4 pt-2 pb-2 text-center" style={{ backgroundColor: removed > 0 ? "#ffd7d1" : "#fafafa" }}>
            <span className="kt-font-bold kt-font-dark">{hideNumber ? "" : `${removed} `}removed</span>
          </div>
        </div>
      </>
    );
  };

  /**
   * Render all changes
   */
  renderChanges = () => {
    const { changes, action } = this.state;
    return (
      <div className="mb-3">
        <div style={{ width: "100%" }}>
          {action === "update" && !!changes && changes.length > 0 ? (
            <>
              {changes
                .filter(x => x.type === "fatal")
                .map(issue => (
                  <div className="my-1 " key={issue.message}>
                    <span className="badge-danger badge-pill px-2">{issue.message}</span>
                  </div>
                ))}
              {changes
                .filter(x => x.type === "error")
                .map(issue => (
                  <div className="my-1 " key={issue.message}>
                    <span className="badge-danger badge-pill px-2" style={{ backgroundColor: "#FF7276" }}>
                      {issue.message}
                    </span>
                  </div>
                ))}
              {changes
                .filter(x => x.type === "warn")
                .map(issue => (
                  <div className="my-1 " key={issue.message}>
                    <span className="badge-warning badge-pill px-2">{issue.message}</span>
                  </div>
                ))}
              {changes
                .filter(x => x.type === "info")
                .map(issue => (
                  <div className="my-1 " key={issue.message}>
                    <span className="badge-info badge-pill px-2">{issue.message}</span>
                  </div>
                ))}
            </>
          ) : (
            <div
              className="pt-2 pb-2"
              style={{
                backgroundColor: "#0abb87",
                borderRadius: "10px"
              }}
            >
              <span className="kt-font-bolder ml-2" style={{ color: "white" }}>
                <i className="fa fa-check mr-2" />
                No conflicts found. Ready to update.
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { onPreferenceChange, preferences, originOrder } = this.props;
    const { show, title, subtitle, note, loading, showChanges, step, changes, statistic, action } = this.state;
    const isComplete = title !== "";
    const hasFatal = !!changes && changes.some(x => x.type === "fatal");
    const hasError = !!changes && changes.some(x => x.type === "error");
    const infoMessage =
      originOrder &&
      !(
        orderUtils.isOrder(originOrder) ||
        [OrderState.OFFER_APPROVED, OrderState.OFFER_RELEASED].includes(originOrder.state)
      )
        ? ""
        : hasFatal && originOrder && orderUtils.isOrder(originOrder)
        ? "Fatal conflicts. Order cannot be updated"
        : hasError && originOrder
        ? "Conflicts require commodity review. Order will be moved to offer state"
        : "";
    return (
      <>
        <button
          className="btn btn-success"
          onClick={e => {
            e.preventDefault();
            this.handleShow();
          }}
        >
          Create Offer
        </button>
        <Modal show={show} id="confirmationModal" onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Offer Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-4">
              <div className="kt-section kt-section--first">
                {showChanges && step === 1 ? (
                  <div className="kt-section__body">
                    <div className="form-group ">
                      <h3 className="kt-section__title mb-2">Action</h3>
                      <div className="input-group">
                        <select className="form-control" onChange={this.handleChangeAction}>
                          <option value="update">Update offer</option>
                          <option value="create">Create new offer</option>
                        </select>
                      </div>
                    </div>
                    {statistic && statistic.capsule && this.renderStatistics("Capsule", statistic.capsule, true)}
                    {statistic && statistic.commodities && this.renderStatistics("Commodities", statistic.commodities)}
                    {statistic && statistic.packaging && this.renderStatistics("Packaging", statistic.packaging)}
                    {originOrder &&
                      (orderUtils.isOrder(originOrder) ||
                        [OrderState.OFFER_APPROVED, OrderState.OFFER_RELEASED].includes(originOrder.state)) &&
                      action !== "create" && (
                        <>
                          <h3 className="kt-section__title mb-2">Conflicts</h3>
                          {this.renderChanges()}
                          {infoMessage !== "" && action === "update" && (
                            <h5 className={hasFatal ? "text-danger" : "text-warning"}>
                              <i className="fa fa-exclamation-circle mr-1" />
                              {infoMessage}
                            </h5>
                          )}
                        </>
                      )}
                  </div>
                ) : (
                  <div className="kt-section__body">
                    <div className="form-group ">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          value={title}
                          onChange={e => this.handleTitleChange(e.target.value.trimStart())}
                          onBlur={e => onPreferenceChange("title", e.target.value.trimStart())}
                          style={title === "" ? { border: "1px solid red" } : {}}
                        />
                      </div>
                      <span className="form-text text-muted">Title for quick identification</span>
                    </div>
                    <div className="form-group ">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="subtitle"
                          value={subtitle}
                          onChange={e => this.handleSubtitleChange(e.target.value.trimStart())}
                          onBlur={e => onPreferenceChange("subtitle", e.target.value.trimStart())}
                        />
                      </div>
                      <span className="form-text text-muted">Short description (optional)</span>
                    </div>
                    <div className="form-group ">
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          id="note"
                          value={note}
                          onChange={e => this.handleNoteChange(e.target.value.trimStart())}
                          onBlur={e => onPreferenceChange("note", e.target.value.trimStart())}
                        />
                      </div>
                      <span className="form-text text-muted">Please write down everything else that is important.</span>
                    </div>
                    <div className="form-group ">
                      <div className="input-group">
                        <select
                          name="priority"
                          className="form-control"
                          onChange={e => onPreferenceChange("priority", e.currentTarget.value)}
                          value={preferences.priority}
                        >
                          <option value="high">High Priority</option>
                          <option value="medium">Medium Priority</option>
                          <option value="low">Low Priority</option>
                        </select>
                      </div>
                    </div>
                    <LanguageSelectionDropdown
                      labelPosition="bottom"
                      wrapperClasses={"form-group"}
                      labelClasses={"form-text text-muted"}
                    />
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleClose}>
              Close
            </button>
            {showChanges && step === 1 && (
              <button
                className={"btn btn-primary " + (hasFatal && action === "update" && "disabled")}
                onClick={this.handleNextStep}
                disabled={hasFatal && action === "update"}
              >
                Next
              </button>
            )}
            {showChanges && step === 2 && (
              <button className="btn btn-secondary" onClick={this.handlePrevStep}>
                Back
              </button>
            )}
            {(!showChanges || (showChanges && step === 2)) && (
              <button
                className={`btn ${isComplete || loading ? "btn-success" : "btn-danger disabled"}`}
                disabled={!isComplete || loading}
                onClick={() => this.handleCreateOffer(hasFatal, hasError)}
              >
                {loading && (
                  <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                    <svg className="button-splash-spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                  </div>
                )}
                {action === "create" ? "Create Offer" : "Update Offer/Order"}
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ConfigurationConfirmationModal;
